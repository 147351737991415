<template>
  <div :class="$style.home">
    <div :class="$style.title"><i class="ri-apps-line" />小程序</div>
    <!-- 微信 -->
    <a-row v-if="info.mini_program">
      <a-col span="12">
        <div :class="$style.box" @click="onDetail('wx')">
          <div :class="$style.avatar">
            <img
              v-if="info.mini_program.wx.authorizer"
              :class="$style.img"
              :src="info.mini_program.wx.info.avatar"
              alt=""
            />
            <img
              v-if="info.mini_program.wx.authorizer"
              :class="$style.mini"
              :src="oss + '/manage/icon/front/miniprogram.png'"
              alt=""
            />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/miniprogram.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.mini_program.wx.authorizer ? $style.nameActive : null]">
                {{ info.mini_program.wx.authorizer ? info.mini_program.wx.info.name : '微信小程序' }}

                <span v-if="!info.mini_program.wx.is_upgrade" :class="$style.upgrade">
                  <i class="ri-arrow-up-line" />
                </span>

                <!-- <span v-if="!info.mini_program.wx.is_upgrade" :class="$style.upgrade">
                  <i class="ri-arrow-up-line" />
                </span> -->
              </span>
              <span class="pull-right">
                <span v-if="info.mini_program.wx.is_online">
                  <a-dropdown v-if="!info.mini_program.wx.authorizer" :trigger="['click']">
                    <span :class="$style.primary"> 授权微信小程序<i class="ri-arrow-down-s-line" /> </span>
                    <a-menu slot="overlay" class="list-drop">
                      <a-menu-item>
                        <Bj-link :class="$style.moreItem" @click="onAuthorization()"> 授权微信小程序</Bj-link>
                      </a-menu-item>
                      <a-menu-item>
                        <Bj-link :class="$style.moreItem" @click="modalRename(item.id)"> 注册微信小程序 </Bj-link>
                      </a-menu-item>
                      <a-menu-item>
                        <Bj-link :class="$style.moreItem" @click="modalDelete(item.id)"> 创建试用小程序 </Bj-link>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                  <a-popover v-else>
                    <template slot="content">
                      <img :class="$style.qrCode" :src="info.mini_program.wx.info.qr_code" alt="" />
                    </template>
                    <i :class="$style.detail" class="ri-eye-line" />
                  </a-popover>
                </span>
                <span v-else :class="$style.disable">
                  敬请期待
                  <i class="ri-arrow-right-s-line" />
                </span>
              </span>
            </p>
            <p class="tip">微信小程序是微信推出的小程序平台，运行在微信内，通过扫码、搜索等多种方式访问。</p>
          </div>
        </div>
      </a-col>
      <!-- 百度 -->
      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img
              v-if="info.mini_program.baidu.authorizer"
              :class="$style.img"
              :src="info.mini_program.baidu.info.avatar"
              alt=""
            />
            <img
              v-if="info.mini_program.baidu.authorizer"
              :class="$style.mini"
              :src="oss + '/manage/icon/front/baidu.png'"
              alt=""
            />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/baidu.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.mini_program.baidu.authorizer ? $style.nameActive : null]"
                >{{ info.mini_program.baidu.authorizer ? info.mini_program.baidu.info.name : '百度小程序' }}
                <i v-if="info.mini_program.baidu.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span v-if="info.mini_program.baidu.is_online">
                  <a-dropdown v-if="info.mini_program.baidu.authorizer" :trigger="['click']">
                    <span :class="$style.primary"> 绑定百度小程序<i class="ri-arrow-down-s-line" /> </span>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <Bj-link :class="$style.moreItem" @click="onAuthorization()"> 授权百度小程序</Bj-link>
                      </a-menu-item>
                      <a-menu-item>
                        <Bj-link :class="$style.moreItem" @click="modalRename(item.id)"> 注册百度小程序 </Bj-link>
                      </a-menu-item>
                      <a-menu-item>
                        <Bj-link :class="$style.moreItem" @click="modalDelete(item.id)"> 创建试用小程序 </Bj-link>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                  <i v-else class="ri-eye-line primary" />
                </span>
                <span v-else :class="$style.disable"> 敬请期待 <i class="ri-arrow-right-s-line" /></span>
              </span>
            </p>
            <p class="tip">百度小程序是百度推出的小程序平台，可在百度及其他超级 APP上运行。</p>
          </div>
        </div>
      </a-col>
      <!-- 抖音 -->
      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img
              v-if="info.mini_program.tt.authorizer"
              :class="$style.img"
              :src="info.mini_program.tt.info.avatar"
              alt=""
            />
            <img
              v-if="info.mini_program.tt.authorizer"
              :class="$style.mini"
              :src="oss + '/manage/icon/front/bytedance.png'"
              alt=""
            />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/bytedance.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.mini_program.tt.authorizer ? $style.nameActive : null]"
                >{{ info.mini_program.tt.authorizer ? info.mini_program.tt.info.name : '字节跳动小程序' }}
                <i v-if="info.mini_program.tt.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span v-if="info.mini_program.tt.is_online">
                  <span v-if="info.mini_program.tt.authorizer" :class="$style.primary">
                    绑定字节跳动小程序<i class="ri-arrow-right-s-line" />
                  </span>
                  <i v-else class="ri-eye-line primary" />
                </span>
                <span v-else :class="$style.disable"> 敬请期待 <i class="ri-arrow-right-s-line" /></span>
              </span>
            </p>
            <p class="tip">字节小程序整体是基于字节跳动全产品矩阵开发，无需进行下载，即点即用的全新应用。</p>
          </div>
        </div>
      </a-col>
      <!-- 支付宝 -->
      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img
              v-if="info.mini_program.tt.authorizer"
              :class="$style.img"
              :src="info.mini_program.tt.info.avatar"
              alt=""
            />
            <img
              v-if="info.mini_program.tt.authorizer"
              :class="$style.mini"
              :src="oss + '/manage/icon/front/bytedance.png'"
              alt=""
            />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/alipay.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.mini_program.tt.authorizer ? $style.nameActive : null]"
                >{{ info.mini_program.tt.authorizer ? info.mini_program.tt.info.name : '支付宝小程序' }}
                <i v-if="info.mini_program.tt.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span v-if="info.mini_program.tt.is_online">
                  <span v-if="info.mini_program.tt.authorizer" :class="$style.primary">
                    绑定支付宝小程序<i class="ri-arrow-right-s-line" />
                  </span>
                  <i v-else class="ri-eye-line primary" />
                </span>
                <span v-else :class="$style.disable"> 敬请期待 <i class="ri-arrow-right-s-line" /></span>
              </span>
            </p>
            <p class="tip">支付宝小程序是支付宝推出的小程序平台，分享支付宝及阿里集团多端流量和商业能力。</p>
          </div>
        </div>
      </a-col>
      <!-- 快应用 -->
      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img
              v-if="info.mini_program.tt.authorizer"
              :class="$style.img"
              :src="info.mini_program.tt.info.avatar"
              alt=""
            />
            <img
              v-if="info.mini_program.tt.authorizer"
              :class="$style.mini"
              :src="oss + '/manage/icon/front/bytedance.png'"
              alt=""
            />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/quick.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.mini_program.tt.authorizer ? $style.nameActive : null]"
                >{{ info.mini_program.tt.authorizer ? info.mini_program.tt.info.name : '快应用' }}
                <i v-if="info.mini_program.tt.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span v-if="info.mini_program.tt.is_online">
                  <span v-if="info.mini_program.tt.authorizer" :class="$style.primary">
                    绑定快应用<i class="ri-arrow-right-s-line" />
                  </span>
                  <i v-else class="ri-eye-line primary" />
                </span>
                <span v-else :class="$style.disable"> 敬请期待 <i class="ri-arrow-right-s-line" /></span>
              </span>
            </p>
            <p class="tip">快应用是手机厂商基于硬件平台共同推出的新型应用生态，无需下载安装，即点即用。</p>
          </div>
        </div>
      </a-col>
    </a-row>
    <div :class="$style.title"><i class="ri-device-line" />网页端</div>
    <a-row v-if="info.web">
      <!-- 移动网页端 -->
      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img :class="$style.img" :src="oss + '/manage/icon/front/h5.png'" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.web.h5.authorizer ? $style.nameActive : null]"
                >{{ info.web.h5.name }}
              </span>
              <span class="pull-right">
                <!-- todo xjq -->
                <!-- <span :class="$style.disable"> 立即开通 <i class="ri-arrow-right-s-line" /></span> -->
                <a-popover>
                  <template slot="content">
                    <img :class="$style.qrCode" :src="info.web.h5.qr_code" />
                  </template>
                  <i :class="$style.detail" class="ri-eye-line" />
                </a-popover>
              </span>
            </p>
            <p class="tip">支持在移动端浏览器中通过链接访问，每个文化云都有自己专属的链接。</p>
          </div>
        </div>
      </a-col>

      <!-- 公众号 -->
      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img
              v-if="info.web.wx_official.authorizer"
              :class="$style.img"
              :src="info.web.wx_official.info.avatar"
              alt=""
            />
            <img
              v-if="info.web.wx_official.authorizer"
              :class="$style.mini"
              :src="oss + '/manage/icon/front/wechat.png'"
              alt=""
            />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/wechat.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.web.wx_official.authorizer ? $style.nameActive : null]"
                >{{ info.web.wx_official.name }}
                <i v-if="info.web.wx_official.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span v-if="!info.web.wx_official.is_usable" :class="$style.btn">
                  立即开通
                  <i class="ri-arrow-right-s-line" />
                </span>
                <span
                  v-if="info.web.wx_official.is_usable && info.web.wx_official.authorizer !== 1"
                  :class="$style.btn"
                >
                  授权微信公众号
                  <i class="ri-arrow-right-s-line" />
                </span>
                <a-popover v-if="info.web.wx_official.is_usable && info.web.wx_official.authorizer === 1">
                  <template slot="content">
                    <img :class="$style.qrCode" :src="info.web.wx_official.qr_code" />
                  </template>
                  <i :class="$style.detail" class="ri-eye-line" />
                </a-popover>
              </span>
            </p>
            <p class="tip">支持在微信浏览器中通过链接访问，可以享受微信公众号部分接口和服务。</p>
          </div>
        </div>
      </a-col>

      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img v-if="info.web.web.authorizer" :class="$style.img" :src="info.web.web.info.avatar" alt="" />
            <img v-if="info.web.web.authorizer" :class="$style.mini" :src="oss + '/manage/icon/front/web.png'" alt="" />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/web.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.web.web.authorizer ? $style.nameActive : null]"
                >{{ info.web.web.name }}
                <i v-if="info.web.web.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span :class="$style.disable"> 敬请期待 <i class="ri-arrow-right-s-line" /></span>
              </span>
            </p>
            <p class="tip">在电脑桌面端通过浏览器访问，每个文化云都有自己专属的链接。</p>
          </div>
        </div>
      </a-col>
    </a-row>
    <div :class="$style.title"><i class="ri-apps-2-line" />APP端</div>
    <a-row v-if="info.app">
      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img v-if="info.app.ios.authorizer" :class="$style.img" :src="info.app.ios.info.avatar" alt="" />
            <img v-if="info.app.ios.authorizer" :class="$style.mini" :src="oss + '/manage/icon/front/ios.png'" alt="" />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/ios.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.app.ios.authorizer ? $style.nameActive : null]"
                >{{ info.app.ios.name }}
                <i v-if="info.app.ios.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span :class="$style.disable"> 敬请期待 <i class="ri-arrow-right-s-line" /></span>
              </span>
            </p>
            <p class="tip">生成你专属的iOS文化云应用。</p>
          </div>
        </div>
      </a-col>

      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img v-if="info.app.android.authorizer" :class="$style.img" :src="info.app.android.info.avatar" alt="" />
            <img
              v-if="info.app.android.authorizer"
              :class="$style.mini"
              :src="oss + '/manage/icon/front/android.png'"
              alt=""
            />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/android.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.app.android.authorizer ? $style.nameActive : null]"
                >{{ info.app.android.name }}
                <i v-if="info.app.android.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span :class="$style.disable"> 敬请期待 <i class="ri-arrow-right-s-line" /></span>
              </span>
            </p>
            <p class="tip">生成你专属的Android文化云应用。</p>
          </div>
        </div>
      </a-col>
    </a-row>
    <div :class="$style.title"><i class="ri-link" />连接端</div>
    <a-row v-if="info.connect">
      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img v-if="info.connect.api.authorizer" :class="$style.img" :src="info.connect.api.info.avatar" alt="" />
            <img
              v-if="info.connect.api.authorizer"
              :class="$style.mini"
              :src="oss + '/manage/icon/front/api.png'"
              alt=""
            />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/api.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.connect.api.authorizer ? $style.nameActive : null]"
                >{{ info.connect.api.name }}
                <i v-if="info.connect.api.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span :class="$style.disable"> 敬请期待 <i class="ri-arrow-right-s-line" /></span>
              </span>
            </p>
            <p class="tip">通过文化云标准接口获取内容和服务。</p>
          </div>
        </div>
      </a-col>
      <a-col span="12">
        <div :class="$style.box">
          <div :class="$style.avatar">
            <img v-if="info.connect.sdk.authorizer" :class="$style.img" :src="info.connect.sdk.info.avatar" alt="" />
            <img
              v-if="info.connect.sdk.authorizer"
              :class="$style.mini"
              :src="oss + '/manage/icon/front/sdk.png'"
              alt=""
            />
            <img v-else :class="$style.img" :src="oss + '/manage/icon/front/sdk.png'" alt="" />
          </div>
          <div :class="$style.content">
            <p :class="$style.top">
              <span :class="[$style.name, info.connect.sdk.authorizer ? $style.nameActive : null]"
                >{{ info.connect.sdk.name }}
                <i v-if="info.connect.sdk.is_upgrade" class="ri-arrow-up-line" />
              </span>
              <span class="pull-right">
                <span :class="$style.disable"> 敬请期待 <i class="ri-arrow-right-s-line" /></span>
              </span>
            </p>
            <p class="tip">通过文化云SDK与你的应用链接。</p>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { terminalManageService } from '@/service'

const service = new terminalManageService()

export default {
  name: 'Home',
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    onAuthorization() {
      this.$router.push({
        name: 'terminalManageWxAuthor',
      })
    },
    onDetail(type) {
      if (type === 'wx' && this.info.mini_program.wx.authorizer) {
        this.$router.push({
          name: 'terminalManageWx',
        })
      }
    },
    async getInfo() {
      try {
        const { data } = await service.getInfo()
        this.info = data
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.home {
  .title {
    margin-bottom: 20px;
    padding-left: 10px;
    color: #000;
    font-size: 16px;

    i {
      margin-right: 5px;
      font-size: 20px;
      vertical-align: -4px;
    }
  }

  :global {
    .ant-col-12 {
      margin-bottom: 20px;
      padding: 0 10px;
    }
  }

  .box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    padding: 20px;
    background: #fff;
    cursor: pointer;

    .avatar {
      position: relative;
      width: 50px;
      height: 50px;
      margin: 0 10px 0 0;
      line-height: 50px;
      text-align: center;

      .img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 50px;
        transform: translate(-50%, -50%);
      }
    }

    .mini {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
    }

    .content {
      flex: 1;

      p {
        margin-bottom: 0;
      }

      .top {
        margin-bottom: 8px;
      }

      .name {
        color: #000;
        font-size: 16px;
      }

      .name-active {
        color: @primary-color;
      }
    }
  }

  .disable {
    color: #bbb;
    font-size: 12px;

    i {
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .primary {
    color: @primary-color;
    cursor: pointer;

    i {
      color: #000;
      font-size: 18px;
    }
  }

  .btn {
    color: @primary-color;
    cursor: pointer;
    font-size: 12px;

    i {
      color: #5c5c5c;
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .detail {
    color: #000;
    font-size: 18px;
  }
}

.qr-code {
  width: 100px;
  height: 100px;
}

.upgrade {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: #fafafa;
  border-radius: 100%;
}
</style>
