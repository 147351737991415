<template>
  <div :class="$style.detail">
    <div :class="$style.left">
      <a-popover :class="$style.tip" title="说明" trigger="hover">
        <template slot="content">
          <p>
            1、如需要修改小程序的信息，请登录微信小程序官方后台修改，点击
            <span class="primary cursor" @click="toManage()">这里</span>
            。
          </p>
          <p>2、小程序信息如果与官方不同步，请重新授权。</p>
        </template>
        <i class="ri-question-line" />
      </a-popover>
      <div :class="$style.avatar"><img :src="data.avatar" alt="" /></div>
      <a-row class="mb-10">
        <a-col span="6">小程序名称</a-col>
        <a-col span="18">{{ data.nickname }}</a-col>
      </a-row>
      <a-row class="mb-10">
        <a-col span="6">主体信息</a-col>
        <a-col span="18">
          <span :class="$style.principal">{{ data.principal_name }}</span>
          <span :class="$style.status">{{ data.principal_type | state }}</span>
        </a-col>
      </a-row>
      <a-row class="mb-10">
        <a-col span="6">微信认证</a-col>
        <a-col span="18" :class="data.is_cert ? $style.cert : null">{{ data.is_cert | is_cert }}</a-col>
      </a-row>
      <a-row>
        <a-col span="6">功能介绍</a-col>
        <a-col span="18">{{ data.nickname }}</a-col>
      </a-row>

      <div :class="$style.line" />

      <a-row>
        <a-col span="6">服务类目</a-col>
        <a-col span="18">
          <div v-for="item in data.category" :key="item" class="mb-6">{{ item }}</div>
        </a-col>
      </a-row>

      <div :class="$style.line" />

      <a-row>
        <a-col span="6">APPID</a-col>
        <a-col span="18">{{ data.app_id }}</a-col>
      </a-row>

      <div :class="$style.state">
        <p :class="$style.time">上次授权时间：{{ data.authorizer_time }}</p>
        <div :class="$style.stateBox">
          <div :class="$style.stateBoxLeft">
            <BjButton
              v-permission="'terminal.multi_terminal.manage-wechat_mini.manage.grant'"
              :loading="loading"
              class="btn-default full-width"
              @click="onAuthor()"
            >
              <i class="ri-link left" />
              重新授权
            </BjButton>
          </div>
          <div :class="$style.stateBoxRight">
            <BjButton
              v-if="data.status"
              v-permission="'terminal.multi_terminal.manage-wechat_mini.manage.service'"
              class="btn-default"
              tooltips="暂停服务"
              @click="onState()"
            >
              <i class="ri-pause-circle-line" />
            </BjButton>
            <BjButton
              v-else
              v-permission="'terminal.multi_terminal.manage-wechat_mini.manage.service'"
              tooltips="启动服务"
              type="primary"
              @click="onState(true)"
            >
              <i class="ri-play-circle-line" />
            </BjButton>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.right">
      <a-tabs v-if="version.online_version" default-active-key="1">
        <a-tab-pane key="1" tab="版本管理">
          <div :class="$style.box">
            <p :class="$style.title">线上版本</p>
            <div v-if="version.online_version.id" :class="$style.boxVersion">
              <div :class="$style.boxVersionImg">
                <p :class="$style.version">{{ version.online_version.version }}</p>
                <p :class="$style.versionText">版本号</p>
              </div>
              <div :class="$style.boxVersionContent">
                <a-row class="mb-10">
                  <a-col span="6"> 发布时间 </a-col>
                  <a-col span="18"> {{ version.online_version.release_time }} </a-col>
                </a-row>
                <a-row>
                  <a-col span="6"> 版本描述 </a-col>
                  <a-col span="18" :title="version.online_version.description" class="ellipsiswx"
                    >{{ version.online_version.description }}
                  </a-col>
                </a-row>
              </div>
              <div :class="$style.boxVersionBtn">
                <BjButton
                  v-permission="'terminal.multi_terminal.manage-wechat_mini.manage.version.rollback'"
                  class="btn-default"
                  :disabled="!!version.online_version.is_fallback_version"
                  :tooltips="version.online_version.is_fallback_version ? '版本已回退，不能再次回退' : null"
                  @click="onRollback()"
                >
                  <i class="ri-arrow-go-back-line left" />
                  版本回退
                </BjButton>
              </div>
            </div>
            <div v-else :class="$style.noData">当前线上尚未发布小程序版本，请从系统版本中选择版本提交审核！</div>
          </div>

          <div :class="$style.box">
            <p :class="$style.title">
              审核版本
              <span
                v-if="version.audit_version.audit_status"
                :class="[$style.audit, version.audit_version.audit_status === 4 ? $style.warn : null]"
                >{{ version.audit_version.audit_status | auditStatus }}</span
              >
            </p>
            <div v-if="version.audit_version.id">
              <div :class="$style.boxVersion">
                <div :class="$style.boxVersionImg">
                  <p :class="$style.version">{{ version.audit_version.version }}</p>
                  <p :class="$style.versionText">版本号</p>
                </div>
                <div :class="$style.boxVersionContent">
                  <a-row class="mb-10">
                    <a-col span="6"> 提交时间 </a-col>
                    <a-col span="18"> {{ version.audit_version.audit_commit_time }} </a-col>
                  </a-row>
                  <a-row>
                    <a-col span="6"> 版本描述 </a-col>
                    <a-col span="18" :title="version.audit_version.description" class="ellipsiswx"
                      >{{ version.audit_version.description }}
                    </a-col>
                  </a-row>
                </div>
                <div :class="$style.boxVersionBtn">
                  <span v-if="version.audit_version.audit_status === 4">
                    <BjButton :class="$style.reason" class="btn-default" @click="onRemark()">
                      <i :class="$style.default" class="ri-eye-line left" />
                      查看原因
                    </BjButton>
                    <a-dropdown>
                      <BjButton :class="$style.more" class="btn-default">
                        <i class="ri-more-fill" />
                      </BjButton>
                      <a-menu slot="overlay">
                        <a-menu-item>
                          <BjLink @click="onRecommit()">再次提交审核</BjLink>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </span>
                  <span v-if="version.audit_version.audit_status === 3 || version.audit_version.audit_status === 2">
                    <BjButton :class="$style.reason" class="btn-default" @click="onRevoke()">
                      <i class="ri-close-circle-line left" />
                      撤回审核
                    </BjButton>
                  </span>
                  <span v-if="version.audit_version.audit_status === 1">
                    <BjButton
                      v-permission="'terminal.multi_terminal.manage-wechat_mini.manage.version.publish'"
                      :class="$style.reason"
                      type="primary"
                      @click="onRelease()"
                    >
                      <i class="ri-send-plane-line left" />
                      立即发布
                    </BjButton>
                  </span>
                </div>
              </div>
            </div>
            <div v-else :class="$style.noData">当前未有审核中的小程序版本，请从系统版本中选择版本提交审核！</div>
          </div>

          <div :class="$style.box">
            <p :class="$style.title">
              系统版本
              <span :class="$style.audit"
                >查看全部版本
                <i class="ri-arrow-right-s-line" />
              </span>
            </p>
            <div :class="$style.boxVersion">
              <div :class="$style.boxVersionImg">
                <p :class="$style.version">{{ version.platform_version.version }}</p>
                <p :class="$style.versionText">版本号</p>
              </div>
              <div :class="$style.boxVersionContent">
                <a-row class="mb-10">
                  <a-col span="6"> 发布时间 </a-col>
                  <a-col span="18"> {{ version.platform_version.audit_commit_time }} </a-col>
                </a-row>
                <a-row>
                  <a-col span="6"> 版本描述 </a-col>
                  <a-col span="18" :title="version.platform_version.description" class="ellipsiswx"
                    >{{ version.platform_version.description }}
                  </a-col>
                </a-row>
              </div>
              <div :class="$style.boxVersionBtn">
                <BjButton
                  v-permission="'terminal.multi_terminal.manage-wechat_mini.manage.version.commit'"
                  class="mr-10"
                  type="primary"
                  :disabled="!version.platform_version.is_upgrade"
                  @click="onCommit()"
                >
                  <i class="ri-shield-flash-line left" />
                  提交审核
                </BjButton>
                <a-dropdown>
                  <BjButton :class="$style.more" class="btn-default">
                    <i class="ri-more-fill" />
                  </BjButton>
                  <a-menu slot="overlay" class="list-drop">
                    <a-menu-item>
                      <BjLink
                        v-permission="'terminal.multi_terminal.manage-wechat_mini.manage.version.trial_qr'"
                        @click="getExperience()"
                        >获取体验二维码</BjLink
                      >
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="成员管理">
          <div :class="$style.tip">
            <div :class="$style.tipAvatar">
              <i class="ri-emotion-line" />
            </div>
            <div :class="$style.tipText">
              <p :class="$style.tipTitle">成员管理</p>
              <p :class="$style.tipDesc">
                体验者成员管理名单，小程序未提交审核前可以发布体验版，体验版仅本名单成员才能体验，小程序管理员无需添加。
              </p>
            </div>
          </div>
          <div :class="$style.userBtn">
            <BjButton
              v-permission="'terminal.multi_terminal.manage-wechat_mini.manage.member.bind'"
              type="primary"
              @click="onAddUser()"
              ><i class="ri-add-line left" />绑定成员</BjButton
            >
          </div>
          <a-table :columns="columns" :data-source="user" :pagination="false" row-key="id">
            <template #action="item">
              <BjLink
                v-permission="'terminal.multi_terminal.manage-wechat_mini.manage.member.unbind'"
                type="primary"
                @click="remove(item)"
                >解绑</BjLink
              >
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
    <bj-modal :visible="visible.user" :body-style="{ height: '400px' }" title="绑定成员" @ok="ok" @cancel="cancel">
      <a-row>
        <a-col class="line-40" span="6">
          <span class="required">*</span>
          微信号
        </a-col>
        <a-col span="16">
          <BjInput v-model="wx_no" placeholder="请输入你需要绑定的用户的微信号" />
        </a-col>
      </a-row>
    </bj-modal>
    <bj-modal :visible="visible.remark" title="查看原因" @ok="visible.remark = false" @cancel="visible.remark = false">
      <div :class="$style.tips">
        小程序审核未通过，请对照以下原因修改，修改完成之后可以再次提交审核。如未通过原因由于小程序代码导致，请联系官方客服。
      </div>
      <div v-if="version.audit_version" v-html="version.audit_version.audit_remark">
        <!-- {{ version.audit_version.audit_remark }} -->
      </div>
    </bj-modal>
    <bj-modal
      :visible="visible.experience"
      title="体验二维码"
      :class="$style.modal"
      :body-style="{ height: '400px', position: 'relative' }"
      @ok="visible.experience = false"
      @cancel="visible.experience = false"
    >
      <i :class="$style.question" class="ri-question-line" />
      <div class="qr-code">
        <img v-if="!qrLoading" :src="experience.qr_code" alt="" />
        <div v-else>
          <a-spin />
        </div>
        <p>
          <i class="ri-qr-scan-line" />
          请体验成员微信扫码预览
        </p>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'

import { terminalManageService } from '@/service'

const service = new terminalManageService()

export default {
  name: 'Home',
  filters: {
    state(val) {
      switch (val) {
        case 0:
          return '个人'
        case 1:
          return '企业'
        case 2:
          return '媒体'
        case 3:
          return '政府'
        case 4:
          return '其他组织'
      }
    },
    is_cert(val) {
      return val === 0 ? '未认证' : '已认证'
    },
    auditStatus(val) {
      switch (val) {
        case 0:
          return '未提交审核'
        case 1:
          return '审核通过'
        case 2:
          return '审核中'
        case 3:
          return '审核延后(节假日等延后审核)'
        case 4:
          return '审核未通过'
      }
    },
  },
  data() {
    return {
      data: {},
      loading: false,
      qrLoading: false,
      user: [],
      version: {},
      visible: {
        remark: false,
        user: false,
        experience: false,
      },
      wx_no: null,
      experience: {
        qr_code: null,
      },
    }
  },
  computed: {
    columns() {
      return [
        { title: '成员ID', dataIndex: 'id', ellipsis: true },
        { title: '微信号', dataIndex: 'wx_no', ellipsis: true },
        { title: '成员编号', dataIndex: 'member_no', ellipsis: true },
        { title: '添加时间', dataIndex: 'created_at', ellipsis: true },
        {
          title: '管理',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getInfo()
    this.getWxVersion()
    this.getUser()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getWxDetail()
        this.data = data
        this.data.category = Object.entries(this.data.category).map(item => item[1])
      } catch (e) {}
    },
    async getWxVersion() {
      try {
        const { data } = await service.getWxVersion()
        this.version = data
      } catch (e) {}
    },
    async getUser() {
      try {
        const { data } = await service.user()
        this.user = data
      } catch (e) {}
    },
    async onAuthor() {
      try {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _this = this
        this.loading = true
        const { data } = await service.getUrl({
          platform_type: 'mini_program',
        })
        this.loading = false
        this.$confirm({
          content: '授权确认',
          onOk() {
            _this.getInfo()
          },
          okText: '已完成',
          cancelText: '未完成',
          onCancel() {
            Modal.destroyAll()
          },
        })
        window.open(data.auth_url)
      } catch (e) {
        this.loading = false
      }
    },
    async onState(type) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        title: type ? '启动服务' : '暂停服务',
        content: type
          ? '请确认是否启动服务？服务启动之后，所有用户都可以正常访问小程序。'
          : '请确认是否暂停服务？服务暂停之后，所有用户访问小程序都将被提示已暂停服务。',
        async onOk() {
          try {
            const { data } = await service.status()
            _this.data.status = data
            _this.$message.success('操作成功')
          } catch (e) {}
        },
        okText: '确定',
        cancelText: '取消',
        onCancel() {
          Modal.destroyAll()
        },
      })
    },
    async onRollback() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        title: '版本回退',
        content: '请确认是否回退版本？版本回退只能回退到小程序的上一个已发布的版本。',
        async onOk() {
          try {
            await service.rollback()
            _this.$message.success('操作成功')
            _this.getWxVersion()
          } catch (e) {}
        },
        okText: '确定',
        cancelText: '取消',
        onCancel() {
          Modal.destroyAll()
        },
      })
    },
    async onRevoke() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        title: '审核撤回',
        content: '请确认是否撤回审核？每天审核撤回次数最多不超过 5 次，一个月不超过 10 次。',
        async onOk() {
          try {
            await service.revoke()
            _this.$message.success('操作成功')
            _this.getWxVersion()
          } catch (e) {}
        },
        okText: '确定',
        cancelText: '取消',
        onCancel() {
          Modal.destroyAll()
        },
      })
    },
    async onRelease() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        title: '立即发布',
        content: '请确认是否发布小程序？本发布是全量发布，发布之后所有用户即为此版本。',
        async onOk() {
          try {
            await service.release()
            _this.$message.success('操作成功')
            _this.getWxVersion()
          } catch (e) {}
        },
        okText: '确定',
        cancelText: '取消',
        onCancel() {
          Modal.destroyAll()
        },
      })
    },
    async onRecommit() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        title: '提交审核',
        content: '请确认是否提交小程序至官方审核？审核时间视微信官方而定，请及时关注。',
        async onOk() {
          try {
            await service.recommit({
              id: _this.version.audit_version.id,
            })
            _this.$message.success('操作成功')
            _this.getWxVersion()
          } catch (e) {}
        },
        okText: '确定',
        cancelText: '取消',
        onCancel() {
          Modal.destroyAll()
        },
      })
    },
    async onCommit() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        title: '提交审核',
        content: '请确认是否提交小程序至官方审核？审核时间视微信官方而定，请及时关注。',
        async onOk() {
          try {
            await service.commit({
              id: _this.version.platform_version.id,
            })
            _this.$message.success('操作成功')
            _this.getWxVersion()
          } catch (e) {}
        },
        okText: '确定',
        cancelText: '取消',
        onCancel() {
          Modal.destroyAll()
        },
      })
    },
    onRemark() {
      this.visible.remark = true
    },
    onAddUser() {
      this.visible.user = true
    },
    async ok() {
      try {
        await service.addUser({
          wx_no: this.wx_no,
        })
        this.$message.success('绑定成功')
        this.cancel()
        this.getUser()
      } catch (e) {}
    },
    cancel() {
      this.wx_no = null
      this.visible.user = false
    },
    async remove(item) {
      await service.unbind({
        id: item.id,
      })
      this.$message.success('解除绑定成功')
      this.getUser()
    },
    async getExperience() {
      try {
        this.qrLoading = true
        this.visible.experience = true
        const { data } = await service.experience({
          id: this.version.platform_version.id,
        })
        this.experience.qr_code = data.qr_code
        this.qrLoading = false
      } catch (e) {}
    },
    toManage() {
      window.open('https://mp.weixin.qq.com/', '_blank')
    },
  },
}
</script>

<style lang="less" module>
.detail {
  display: flex;
  flex-direction: row;

  .left {
    position: relative;
    width: 380px;
    margin-right: 20px;
    padding: 20px;
    font-size: 13px;
    background: #fff;

    :global {
      .ant-col-18 {
        width: calc(100% - 100px);
        color: #000;
      }

      .ant-col-6 {
        width: 100px;
        color: #5c5c5c;
      }
    }

    .tip {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #5c5c5c;
      font-size: 20px;
    }

    .avatar {
      width: 80px;
      height: 80px;
      margin: 30px auto 40px;
      overflow: hidden;
      border-radius: 100%;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .line {
      width: 100%;
      margin: 20px 0;
      border-top: 1px solid #eee;
    }

    .principal {
      margin-right: 10px;
    }

    .status {
      display: inline-block;
      height: 19px;
      padding: 0 5px;
      color: #fff;
      line-height: 19px;
      background: @primary-color;
      border-radius: 6px;
    }

    .cert {
      color: @primary-color;
    }
  }

  .right {
    flex: 1;
    padding: 20px;
    background: #fff;

    :global {
      .ant-tabs {
        height: 100%;
      }
    }

    .box {
      width: 99%;
      height: 184px;
      margin: 0 auto 20px;
      padding: 20px;
      border-radius: 6px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

      .title {
        margin-bottom: 20px;
        color: #000;
        font-size: 16px;
        line-height: 24px;

        .audit {
          float: right;
          font-size: 12px;
          cursor: pointer;

          i {
            color: #5c5c5c;
            font-size: 18px;
            vertical-align: -4px;
          }
        }
      }

      .box-version {
        display: flex;

        .box-version-img {
          width: 100px;
          height: 100px;
          padding: 20px 0;
          text-align: center;
          background: #fafafa;
          border-radius: 6px;

          .version {
            margin: 0;
            color: @primary-color;
            font-size: 24px;
          }

          .version-text {
            margin: 0;
            color: #5c5c5c;
            font-size: 12px;
          }
        }

        .box-version-content {
          flex: 1;
          padding-left: 20px;

          :global {
            .ant-col-18 {
              width: calc(100% - 100px);
              color: #000;
            }

            .ant-col-6 {
              width: 100px;
              color: #5c5c5c;
            }
          }
        }

        .box-version-btn {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 200px;

          .reason {
            margin-right: 10px;
          }
        }
      }
    }

    .tip {
      display: flex;
      align-items: center;
      width: 100%;
      height: 90px;
      margin-bottom: 20px;
      background: #fafafa;

      &-avatar {
        width: 50px;
        height: 50px;
        margin: 20px 12px 20px 20px;
        line-height: 50px;
        text-align: center;
        background: #fff;
        border-radius: 100%;

        i {
          font-size: 20px;
        }
      }

      &-text {
        flex: 1;

        p {
          margin: 0;
        }

        p:nth-child(1) {
          margin-bottom: 4px;
        }
      }

      &-title {
        margin-bottom: 4px;
        color: #000;
        font-size: 16px;
      }

      &-desc {
        color: #5c5c5c;
        font-size: 12px;
      }
    }
  }

  .state {
    position: absolute;
    bottom: 20px;
    width: 340px;
    text-align: center;

    .time {
      margin-bottom: 10px;
      color: #5c5c5c;
      font-size: 12px;
    }

    .state-box {
      display: flex;
    }

    .state-box-left {
      width: 300px;
      margin-right: 10px;
      color: #5c5c5c;
    }

    .state-box-right {
      flex: 1;

      :global {
        .ant-btn {
          padding: 0 6px;
        }
      }
    }
  }

  .no-data {
    margin-top: 50px;
    color: #bbb;
    text-align: center;
  }

  .warn {
    color: #ff9502 !important;
  }

  .tips {
    padding: 20px;
    background: #5c5c5c;
  }

  .user-btn {
    text-align: right;
    margin-bottom: 20px;
  }
}

.modal {
  .question {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #5c5c5c;
    font-size: 20px;
  }
}

.more {
  width: 32px;
  padding: 0;
}
</style>
